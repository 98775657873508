import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Root from "./container/Root";
import Rollbar from "rollbar";
import { ErrorBoundary, Provider } from "@rollbar/react";
import { OpenFeature, OpenFeatureProvider } from '@openfeature/react-sdk';
import { EnvVarProvider } from "./providers/EnvVarProvider.ts";

const rollbarConfig = new Rollbar({
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  environment: process.env.REACT_APP_ROLLBAR_ENVIRONMENT,
  captureUncaught: true,
  captureUnhandledRejections: true,
});

// Instantiate and set feature flagging provider
OpenFeature.setProvider(new EnvVarProvider());

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider config={rollbarConfig}>
    <ErrorBoundary>
      <OpenFeatureProvider>
        <Root />
      </OpenFeatureProvider>
    </ErrorBoundary>
  </Provider>
);
